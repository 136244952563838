<template>
  <div class="members-directory">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
      <div >
        <h3 class="box-title green-text">
          {{ $t("payment.previousInvoiceTitle") }}
        </h3>
      </div>
    </div>
    <ApiErrorMessage :message="apiErrorMessage" />
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table
            :header-props="{ sortIcon: null }"
            :headers="headers"
            :items="invoices"
            :disable-sort="true"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            fixed-header
            :show-expand="false"
            class="color-light-green"
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
          >
            <template v-slot:item.status="{ item }">
              <span>{{
                item.status === 0
                  ? "Pending"
                  : item.status === 1
                  ? "Success"
                  : "Card Error"
              }}</span>
            </template>
            <template v-slot:item.ends_at="{ item }">
              <span>{{ item.ends_at | MMDDYYdatefilter }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>${{ item.amount }}</span>
            </template>
            <template v-slot:item.starts_at="{ item }">
              <span>{{ item.starts_at | MMDDYYdatefilter }}</span>
            </template>
            <template v-slot:item.payment_method_details.card.last4="{ item }">
              <span>{{
                item.payment_method_details &&
                "&bull; &bull; &bull; &bull;" +
                  item.payment_method_details.card.last4
              }}</span>
            </template>
            <template v-slot:item.receipt_url="{ item }">
              <v-btn
                small
                v-if="item.payment_charge_id"
                @click="getReceiptUrl(item.payment_charge_id)"
                class="
                  btn customBtn pattern-btn
                   text-capitalize white-text
                "
                elevation="0"
                >
                <span>{{ $t("payment.receipt") }}</span>
              </v-btn>
              <!-- <span v-if="item.receipt_url"
                ><a
                  class="view_receipt"
                  target="_blank"
                  :href="item.receipt_url"
                  >View Receipt</a
                ></span
              > -->
              <span v-if="!item.receipt_url">{{ item.failed_reason }}</span>
            </template>
          </v-data-table>
          <!-- <div class="text-center">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="fetchInvoices"
            ></v-pagination>
          </div> -->
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";
export default {
  name: "PreviousInvoice",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",

      loading: false,
      page: 1,
      id: null,
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      headers: [
        {
          text: this.$t("payment.startDate"),
          align: "left",
          value: "starts_at",
        },
        {
          text: this.$t("payment.endDate"),
          align: "left",
          value: "ends_at",
        },
        {
          text: this.$t("payment.amount"),
          align: "left",
          value: "amount",
        },
        {
          text: this.$t("payment.paymentReceipt"),
          align: "left",
          value: "receipt_url",
        },
        {
          text: this.$t("payment.status"),
          align: "left",
          value: "status",
        },
        {
          text: this.$t("payment.cardDetail"),
          align: "left",
          value: "payment_method_details.card.last4",
        },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },

    ...mapGetters({
      invoices: "payment/getInvoices",
      paymentReceiptUrl: "payment/getPaymentReceiptUrl",
    }),
  },
  async mounted() {
    await this.fetchInvoices();
  },

  methods: {
    ...mapActions({
      getInvoices: "payment/getInvoices",
      getPaymentReceiptUrl: "payment/getPaymentReceiptUrl",
    }),

    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchInvoices(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchInvoices(); // Fetch new data based on the current page
      }
    },

    async fetchInvoices() {
      this.loading = true;
      try {
        await this.getInvoices({
          page: this.currentPage,
          items_per_page: this.perPage,
        });

        if (this.invoices.meta) {          
          this.totalEntries = this.invoices.meta.total;
          this.itemsPerPage = parseInt(this.invoices.meta.per_page);
          this.pageCount = this.invoices.meta.last_page;
        } else {
          this.itemsPerPage = this.invoices.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async getReceiptUrl(payment_charge_id) {
      this.loading = true;
      try {
        await this.getPaymentReceiptUrl({
          payment_charge_id: payment_charge_id
        });

        if(this.paymentReceiptUrl.data) {
          window.open(this.paymentReceiptUrl.data, '_blank');
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    updateCardDetails() {
      this.$router.push({
        name: "UpdateVehicle",
      });
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
